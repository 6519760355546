import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    comercios: [],
    noticias: [],
    categorias: [],
    promos: []
  },
  mutations: {
    setComercios(state, value) {
      state.comercios = value
    },
    setNoticias(state, value) {
      state.noticias = value
    },
    setCategorias(state, value) {
      state.categorias = value
    },
    setPromos(state, value) {
      state.promos = value
    }
  },
  actions: {
    setComercios(context, value) {
      context.commit('setComercios', value)
    },
    setNoticias(context, value) {
      context.commit('setNoticias', value)
    },
    setCategorias(context, value) {
      context.commit('setCategorias', value)
    },
    setPromos(context, value) {
      context.commit('setPromos', value)
    }
  },
  getters: {
    getComercios: (state) => {
    let array = state.comercios
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements array[i] and array[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = array[i]; array[i] = array[j]; array[j] = t
         [array[i], array[j]] = [array[j], array[i]];
      }
      return array
    },
    getComercioById: (state) => id => {
      return state.comercios.find(comercio => comercio.idComercio == id)
    },
    getNoticias: (state) => {
      return state.noticias
    },
    getNoticiaById: (state) => id => {
      return state.noticias.find(noticia => noticia.idNoticia == id)
    },
    getCategorias: (state) => {
      return state.categorias
    },
    getPromos: (state) => {
      return state.promos
    },
    getPromoById: (state) => id => {
      return state.promos.find(promo => promo.idPromo == id)
    }
  }
})
