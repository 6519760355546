<template>
  <v-app>
    <v-toolbar height="90">
      <router-link to="/" class="ma-auto">
        <img
          :src="require('@/assets/icon.png')"
          height="85"
        />
      </router-link>
    </v-toolbar>
    <v-main>
      <router-view/>
    </v-main>
    <footer>
      2021 © <a href="https://www.exolution.es/" target="_blank">Exolution Grupo Tecnológico</a>
    </footer>
  </v-app>
</template>

<script>
import httpService from '@/services/httpService';

export default {
  name: 'App',
  created() {
    httpService.get('public/comercios').then(response => {
      console.log(response);
      if (response.statusText == 'OK') {
        this.$store.dispatch('setComercios', response.data);
      }
    });
    httpService.get('public/categorias').then(response => {
      if (response.statusText == 'OK') {
        this.$store.dispatch('setCategorias', response.data);
      }
    });
    // httpService.get('public/noticias').then(response => {
    //   if (response.statusText == 'OK') {
    //     this.$store.dispatch('setNoticias', response.data);
    //   }
    // });
    // httpService.get('public/promos').then(response => {
    //   if (response.statusText == 'OK') {
    //     this.$store.dispatch('setPromos', response.data);
    //   }
    // });
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.v-toolbar {
  z-index: 5;
  max-height: 100px;
}

input, select {
 outline:none;
}

footer {
  background-color: rgba(#f48727, 0.3);
  color: gray;
  padding: 15px 0;
  a {
    color: gray !important;
    font-weight: bold;
  }
}
</style>
